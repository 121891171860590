import { grey } from "@mui/material/colors";

const styles = {
  button: {
    backgroundColor: "white",
    color: "primary.dark",
    border: `1px solid`,
    borderColor: "primary.main",
    borderRadius: 2,
    mr: 2,
    "&:hover": {
      backgroundColor: grey[100],
    },
    fontSize: { xs: 11, md: 14 },
  },
  border: {
    borderBottom: `1px solid ${grey[300]}`,
    px: 2,
    py: 1,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  header: {
    backgroundColor: { xs: "secondary.light", md: "none" },
    fontSize: 16,
    py: 1,
    px: 2,
    display: "flex",
    alignItems: "center",
  },
  close: {
    width: 20,
    height: 20,
    fontSize: 14,
  },
  automationRow: {
    width: "100%",
    textAlign: "left",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: grey[50],
    },
  },
};

export default styles;

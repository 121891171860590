import { useAuth } from "@clerk/clerk-react";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  Divider,
  IconButton,
  Switch,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { AlertContext } from "contexts/Alert";
import { AutomationsContext } from "contexts/Automations";
import { OrganizationUserContext } from "contexts/Organization";
import { SavedBrandCollectionsContext } from "contexts/SavedBrandCollections";
import { useContext, useState } from "react";
import { Automation } from "schemas/dashboard";

import { fetcherAuth } from "utils/api";
import { daysOfWeek } from "utils/time";
import { trackEvent } from "utils/tracking";
import { makeDeepCopy } from "utils/updateLocalState";

import CreateAutomationDialog from "./CreateAutomationDialog";
import styles from "./styles";

const AutomationVA = () => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { getToken } = useAuth();
  const { currentOrg } = useContext(OrganizationUserContext);

  const { collections } = useContext(SavedBrandCollectionsContext);
  const { automations, setAutomations, fetchLoading } =
    useContext(AutomationsContext);
  const { setErrorAlert, setAlert } = useContext(AlertContext);

  const [open, setOpen] = useState(false);
  const [openCreate, setOpenCreate] = useState(false);
  const [automationId, setAutomationId] = useState<number>(-1);

  const closeCreateDialog = () => {
    setAutomationId(-1);
    setOpenCreate(false);
  };

  const handleCreateSearch = () => {
    setOpenCreate(true);
    trackEvent("Create Automation Button Clicked");
  };

  const saveAutomation = async (automation: Automation) => {
    if (!automation.id) return;
    try {
      const res = await fetcherAuth(
        getToken,
        `/api/organization/${currentOrg?.id}/automations/${automation.id}`,
        automation?.id ? "PUT" : "POST",
        {},
        automation,
      );
      setAutomations((prev) => {
        const copy = makeDeepCopy(prev);
        const index = copy?.findIndex(
          (x: Automation) => x.id === res.automation?.id,
        );
        if (index === -1) {
          copy.push(res.automation);
        } else {
          copy[index] = res.automation;
        }
        return copy;
      });
      setAlert("Successfully updated your automation", "success");
    } catch (error) {
      setErrorAlert(error);
    }
  };

  const handleIsTopNicheToggle = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    toggledAutomationId: number,
  ) => {
    event.stopPropagation();
    const automationToChange = makeDeepCopy(
      automations.find((a) => a.id === toggledAutomationId),
    );
    automationToChange.isTopNiche = !automationToChange.isTopNiche;
    saveAutomation(automationToChange);
  };

  return (
    <>
      {!isMobileScreen && (
        <Button
          sx={styles.button}
          disableElevation
          size="small"
          variant="contained"
          onClick={() => setOpen(true)}
        >
          Automations ✨
        </Button>
      )}

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="sm"
        fullScreen={isMobileScreen}
      >
        <DialogTitle sx={styles.header}>
          <Box sx={{ flexGrow: 1 }}>My Automated Searches</Box>

          <IconButton sx={styles.close} onClick={() => setOpen(false)}>
            <Box component="i" className="fa-regular fa-xmark" />
          </IconButton>
        </DialogTitle>
        {fetchLoading && (
          <Box alignItems="center" sx={{ px: 2, pb: 2 }}>
            Loading your collections...{" "}
            <CircularProgress size={20} sx={{ ml: 1 }} />
          </Box>
        )}
        <Box>
          <Divider />
          {automations?.map((automation, index) => (
            <Box
              key={index}
              sx={styles.automationRow}
              onClick={() => setAutomationId(automation.id || -1)}
            >
              <Box key={index} sx={styles.border}>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography sx={{ fontSize: 14 }}>
                    {daysOfWeek[automation.dayOfWeek]} - {automation.count}{" "}
                    Brands
                  </Typography>

                  <Typography sx={{ fontSize: 12 }}>
                    Collection:{" "}
                    {automation?.options?.collection_id &&
                    automation?.options?.collection_id in collections
                      ? collections[automation.options.collection_id]?.name
                      : ""}
                    {automation?.options?.collection_id === 0 && "All Saved"}
                    {automation?.options?.collection_id === null && (
                      <em>New Collection (to be created)</em>
                    )}
                  </Typography>
                </Box>

                <Typography sx={{ mr: 1 }}>Is Top Niche</Typography>
                <Switch
                  checked={automation.isTopNiche}
                  onClick={(e) => handleIsTopNicheToggle(e, automation.id!)}
                  color="primary"
                />

                {automation.id && (
                  <Box>
                    <Box component="i" className="fa-regular fa-arrow-right" />
                  </Box>
                )}
              </Box>
            </Box>
          ))}

          <Button
            size="small"
            onClick={handleCreateSearch}
            sx={{ my: 1, ml: 1 }}
          >
            + Create New Automation
          </Button>
        </Box>

        {(openCreate || automationId > 0) && (
          <CreateAutomationDialog
            open={automationId > 0 || openCreate}
            handleClose={closeCreateDialog}
            editAutomation={automations?.find((x) => x.id === automationId)}
          />
        )}
      </Dialog>
    </>
  );
};

export default AutomationVA;
